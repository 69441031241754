<template>
  <div class="row">
    <div class="col-12">
      <template>
        <div class="w-100 row mx-0 px-0 d-flex justify-content-end">
          <div
            class="col-xl-4 col-lg-4 col-md-5 col-sm-6 mb-3 px-0 d-flex justify-content-end"
          >
            <form @submit.prevent="getAgenda">
              <text-input
                :model.sync="agenda_search"
                :placeholder="$t('general.search_filter')"
                :required="false"
                :title="$t('general.search')"
              ></text-input>
            </form>
          </div>
        </div>
        <div class="calendar-parent calendar_h" style="height: 70vh">
          <calendar-view
            :class="themeClasses"
            :current-period-label="useTodayIcons ? 'icons' : ''"
            :date-classes="myDateClasses"
            :disable-future="disableFuture"
            :disable-past="disablePast"
            :display-period-count="displayPeriodCount"
            :display-period-uom="displayPeriodUom"
            :displayWeekNumbers="displayWeekNumbers"
            :enable-date-selection="true"
            :enable-drag-drop="false"
            :items="formattedItemsForAgenda"
            :period-changed-callback="periodChanged"
            :selection-end="selectionEnd"
            :selection-start="selectionStart"
            :show-date="showDate"
            :show-times="showTimes"
            :starting-day-of-week="startingDayOfWeek"
            :time-format-options="{ hour: 'numeric', minute: '2-digit' }"
            @click-date="onClickDay"
          >
            <calendar-view-header
              slot="header"
              slot-scope="{ headerProps }"
              :header-props="headerProps"
              @input="setShowDate"
            />
            <template slot="item" slot-scope="{ value, top, originalItem }">
              <div
                :class="value.classes"
                :style="{ top: top.replace('*1.4em', '*3em') }"
                :title="value.originalItem.order_number"
                class="cv-item past span1 cursor-pointer"
                draggable="false"
                @click="onClickItem($event, value.originalItem)"
              >
                <div
                  class="d-flex justify-content-between items-center"
                  v-b-tooltip.hover
                  v-b-tooltip.left
                  placement="bottom"
                  :title="value.originalItem.supplier_company_name"
                >
                  <div class="d-inline-block">{{ value.originalItem.order_number }}</div>
                  <div class="d-flex justify-content-end items-center">
                    <div class="d-flex flex-column">
                      <div class="d-flex justify-content-between text-sm">
                        <span class="d-inline-block pr-1"
                          >P {{
                            value.originalItem.planned > 0
                              ? Number(value.originalItem.planned).toLocaleString("tr-TR")
                              : "-"
                          }}/{{
                            value.originalItem.actual > 0
                              ? Number(value.originalItem.actual).toLocaleString("tr-TR")
                              : "-"
                          }}
                          G </span
                        >
                      </div>
                      <div class="d-flex justify-content-between text-sm">
                        <span class="d-inline-block pr-1"
                          >{{ value.originalItem.color_name }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="px-2"
                      v-html="
                        getIconByKey(value.originalItem.status_icon, {
                          class: 'w-20px h-20px d-inline-block object-cover',
                        })
                      "
                    ></div>
                  </div>
                </div>
              </div>
            </template>
          </calendar-view>
        </div>
        <div class="d-flex justify-content-center py-10">
          <div
            :class="{
              'opacity-50':
                selectedExpenseType !== expenses['kesim'] && selectedExpenseType !== null,
            }"
            class="w-200px py-2 mx-3 cursor-pointer border-15px bg-cut text-center font-weight-bold"
            @click="changeSelectedExpenseType(expenses['kesim'])"
          >
            {{ $t("following.cut").toUpperCase() }}
          </div>
          <div
            :class="{
              'opacity-50':
                selectedExpenseType !== expenses['dikim'] && selectedExpenseType !== null,
            }"
            class="w-200px py-2 mx-3 cursor-pointer border-15px bg-knit text-center font-weight-bold"
            @click="changeSelectedExpenseType(expenses['dikim'])"
          >
            {{ $t("following.knit").toUpperCase() }}
          </div>
          <div
            :class="{
              'opacity-50':
                selectedExpenseType !== expenses['utu_paket'] &&
                selectedExpenseType !== null,
            }"
            class="w-200px py-2 mx-3 cursor-pointer border-15px bg-iron text-center font-weight-bold"
            @click="changeSelectedExpenseType(expenses['utu_paket'])"
          >
            {{ $t("following.iron").toUpperCase() }}
          </div>
          <div
            :class="{
              'opacity-70': selectedExpenseType !== 4 && selectedExpenseType !== null,
            }"
            class="w-200px py-2 mx-3 cursor-pointer all-button bg-white text-center font-weight-bold"
            @click="changeSelectedExpenseType()"
          >
            {{ $t("following.all").toUpperCase() }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
} from "@/core/services/store/REST.module";
import {
  EXPORT,
  EXPORT_PDF,
} from "@/core/services/store/following-production/following_production.module";
import moment from "moment";
import { LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT } from "@/core/config/constant";
import DataTable from "@/assets/components/dataTable/DataTable";

import "vue-simple-calendar/static/css/default.css";
import "vue-simple-calendar/static/css/holidays-us.css";
import "vue-simple-calendar/static/css/gcal.css";
// TODO: remove import and delete file
import { CalendarMathMixin, CalendarView, CalendarViewHeader } from "vue-simple-calendar"; // published version

import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import TextInput from "@/assets/components/inputs/TextInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import { FOLLOWING_PRODUCTION_CALENDAR_SHOW_DATE } from "@/core/storage/storage-names";

export default {
  name: "FollowingProductionCalendarView",
  components: {
    TextInput,
    InputFilter,
    DashboardBox,
    DataTable,
    CalendarView,
    CalendarViewHeader,
    DateFilter,
    CustomMultiSelect,
    SelectFilter,
  },
  mixins: [CalendarMathMixin],
  beforeRouteLeave(to, from, next) {
    this.config.content.width = "fixed";
    next();
  },
  data() {
    return {
      expenses: [],
      selectedExpenseType: null,
      startingDayOfWeek: 0,
      showTimes: true,
      showDate: this.thisMonth(1),
      selectionEnd: null,
      selectionStart: null,
      displayWeekNumbers: false,
      displayPeriodUom: "month",
      displayPeriodCount: 1,
      disablePast: false,
      disableFuture: false,
      useTodayIcons: true,
      agenda_search: null,
      agendaItems: [],
      filters: {
        search: null,
        start_date: moment(this.showDate),
        end_date: moment(this.showDate).add(1, "month"),
      },
    };
  },
  methods: {
    changeSelectedExpenseType(type_id) {
      if (Number(type_id) === Number(this.selectedExpenseType)) {
        this.selectedExpenseType = null;
      } else {
        this.selectedExpenseType = Number(type_id);
      }
      this.getAgenda();
    },
    onClickItem(e, payload) {
      this.$router.push({
        name: "following_production.edit",
        params: {
          id: payload.id,
        },
      });
    },
    thisMonth(d, h, m) {
      const t = new Date();
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0);
    },
    setShowDate(d) {
      this.showDate = d;
    },
    getExpenses() {
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/orders/expenses/details/expenses",
          showLoading: true,
        })
        .then((response) => {
          if (response.status) {
            this.expenses = response.data;
          }
        });
    },
    getAgenda() {
      let self = this;
      let filters = null;

      filters = {
        search: this.agenda_search,
        type_id: this.selectedExpenseType,
        start_date: moment(this.showDate)
          .subtract(1, "days")
          .format(LARAVEL_DATE_TIME_FORMAT),
        end_date: moment(this.showDate).add(1, "month").format(LARAVEL_DATE_TIME_FORMAT),
      };
      localStorage.setItem(
        FOLLOWING_PRODUCTION_CALENDAR_SHOW_DATE,
        moment(this.showDate)
      );

      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/orders/expenses/details/targets",
          filters: filters,
          showLoading: true,
        })
        .then((response) => {
          if (response.status) {
            self.agendaItems = response.data;
          }
        });
    },
    periodChanged() {
      // range, eventSource) {
      // Demo does nothing with this information, just including the method to demonstrate how
      // you can listen for changes to the displayed range and react to them (by loading items, etc.)
      //console.log(eventSource)
      //console.log(range)
    },
    onClickDay(d) {
      this.selectionStart = null;
      this.selectionEnd = null;
      this.message = `You clicked: ${d.toLocaleDateString()}`;
    },
    onClickItem(e, payload) {
      this.$router.push({
        name: "following_production.edit",
        params: {
          id: payload.id,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      layoutConfig: ["layoutConfig"],
    }),
    config() {
      return this.layoutConfig();
    },
    formattedItemsForAgenda() {
      let items = [];
      if (this.agendaItems.length < 1) return items;
      let classes = {};
      Object.entries(this.expenses).forEach(([key, value]) => {
        if (key == "kesim") {
          classes[value] = "bg-cut";
        } else if (key == "dikim") {
          classes[value] = "bg-knit";
        } else if (key == "utu_paket") {
          classes[value] = "bg-iron";
        }
      });
      this.agendaItems.map((agenda, index) => {
        let status = 0,
          status_icon = "icons.waybill.waybill_entry";
        if (Number(agenda.actual) === 0) {
          status = 0;
          status_icon = "icons.waybill.waybill_entry";
        } else if (Number(agenda.actual) < Number(agenda.target)) {
          status = 1;
          status_icon = "icons.waybill.waybill_exit";
        } else {
          status = 2;
          status_icon = "icons.waybill.down_arrow";
        }

        items.push({
          id: agenda.order_expense_detail_id,
          startDate: moment(agenda.date, LARAVEL_DATE_FORMAT).toDate(),
          classes: [classes[agenda.order_expense_detail.order_expense.expense_id]],
          order_number: agenda.order_expense_detail.order_expense.order.order_number,
          color_name: agenda.order_expense_detail.order_size_and_color.color_name,
          type_id: agenda.order_expense_detail.order_expense_id,

          // title: agenda.order_expense.order.order_number,
          title: agenda.order_expense_detail.order_expense.order.order_number,
          planned: agenda.target || "-",
          actual: agenda.actual || "-",
          status: status,
          status_icon: status_icon,
          supplier_company_name: agenda.order_expense_detail.supplier_company.name,
        });
      });
      return items;
    },
    myDateClasses() {
      const o = {};
      const theFirst = this.thisMonth(1);
      const ides = [2, 4, 6, 9].includes(theFirst.getMonth()) ? 15 : 13;
      const idesDate = this.thisMonth(ides);
      o[this.isoYearMonthDay(idesDate)] = "ides";
      o[this.isoYearMonthDay(this.thisMonth(21))] = ["do-you-remember", "the-21st"];
      return o;
    },
    themeClasses() {
      return {
        "theme-default": this.useDefaultTheme,
        "holiday-us-traditional": this.useHolidayTheme,
        "holiday-us-official": this.useHolidayTheme,
      };
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.following_production"),
        route: "following_production.index",
      },
      { title: this.$t("general.dashboard") },
    ]);

    if (!this.isUserGranted("FollowingProduct", ["viewAny"])) {
      return false;
    }
    this.config.content.width = "fluid";
    this.show_date = localStorage.hasOwnProperty(FOLLOWING_PRODUCTION_CALENDAR_SHOW_DATE)
      ? moment(localStorage.getItem(FOLLOWING_PRODUCTION_CALENDAR_SHOW_DATE))
      : this.thisMonth(1);
    this.getExpenses();
    this.getAgenda();
  },
  created() {
    this.$store.commit(SET_ITEMS, {});
  },
  watch: {
    showDate(newValue, oldValue) {
      this.getAgenda();
      this.filters.start_date = moment(this.showDate).subtract(1, "days");
      this.filters.end_date = moment(this.showDate).add(1, "month");
    },
  },
};
</script>

<style lang="scss">
$cut_color: #e097ff;
$knit_color: #ffac70;
$iron_color: #ff95d5;
$primary_color: #462985;
$names: (
  "cut": $cut_color,
  "knit": $knit_color,
  "iron": $iron_color,
);

@each $name, $color in $names {
  .bg-#{$name} {
    background-color: $color !important;
  }

  .text-#{$name} {
    color: $color !important;
  }
}

.vue-excel-editor thead th {
  background-color: yellow !important;
}

.bg-warning {
  background-color: #ffd5d7 !important;
}

.bg-fine {
  background-color: #d6f3eb !important;
}

html,
body {
  height: 100%;
  margin: 0;
  background-color: #f7fcff;
}

#app {
  display: flex;
  flex-direction: row;
  font-family: Calibri, sans-serif;
  width: 95vw;
  min-width: 30rem;
  max-width: 100rem;
  min-height: 40rem;
  margin-left: auto;
  margin-right: auto;
}

.calendar-controls {
  margin-right: 1rem;
  min-width: 14rem;
  max-width: 14rem;
}

.calendar-parent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 80vh;
  background-color: white;
}

/* For long calendars, ensure each week gets sufficient height. The body of the calendar will scroll if needed */
.cv-wrapper.period-month.periodCount-2 .cv-week,
.cv-wrapper.period-month.periodCount-3 .cv-week,
.cv-wrapper.period-year .cv-week {
  min-height: 6rem;
}

/* These styles are optional, to illustrate the flexbility of styling the calendar purely with CSS. */
/* Add some styling for items tagged with the "birthday" class */
.theme-default .cv-item.birthday {
  background-color: #e0f0e0;
  border-color: #d7e7d7;
}
.cv-item {
  border-radius: 8px;
}

.theme-default .cv-item.birthday::before {
  content: "\1F382";
  /* Birthday cake */
  margin-right: 0.5em;
}

.box {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  margin: 5px;
}

.checkboxClass {
  display: inline-block;
  font-size: 1em !important;
}

input[type="checkbox"] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(01);
  /* IE */
  -moz-transform: scale(1);
  /* FF */
  -webkit-transform: scale(1);
  /* Safari and Chrome */
  -o-transform: scale(1);
  /* Opera */
  transform: scale(1);
  display: inline-block;
  width: 25px;
  height: auto;
}

.field label.checkbox {
  font-size: 1rem;
  font-weight: 300 !important;
  line-height: 1.1rem;
}

select.btn-mini {
  line-height: 14px;
  width: auto;
}

.checkbox-wrapper {
  white-space: nowrap;
}

.checkbox {
  vertical-align: top;
  display: inline-block;
}

.checkbox-label {
  white-space: normal;
  display: inline-block;
}

.calendar_h {
  height: 600px !important;
}

.cv-header {
  align-content: center !important;
  display: flex;
  justify-content: center !important;
}

.cv-header .periodLabel {
  display: inline-block !important;
  flex: none;
}

.all-button {
  border: 1px solid $primary_color;
  border-radius: 15px;
}
</style>
